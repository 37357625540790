import {
	Edit,
	SimpleForm,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh,
	NumberInput,
	NumberField
} from "react-admin";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { validateDiseaseData } from "./diseaseValidate";

export const DiseaseEdit = (props) => {
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();

	const onSuccess = () => {
		notify(`Updated Successfully!`);
		redirect("/diseases");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<Edit
			{...props}
			onSuccess={onSuccess}
			onFailure={onFailure}
			mutationMode="pessimistic"
			title={"Edit Disease"}
		>
			<SimpleForm
				toolbar={<CustomToolbar />}
				validate={validateDiseaseData}
				syncWithLocation={false}
			>
				<TextInput source="name" />
				<NumberField source="index" label="Display Index" />
				<NumberInput source="newIndex" />
			</SimpleForm>
		</Edit>
	);
};
