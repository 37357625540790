import { FeatureCreate } from "./featureCreate";
import { FeatureEdit } from "./featureEdit";
import { FeatureList } from "./featureList";
import { FeatureShow } from "./featureShow";

export default {
	create: FeatureCreate,
	edit: FeatureEdit,
	list: FeatureList,
	show: FeatureShow,
};
