export const validateProductData = async ({
	name, price, currency
}) => {
	const errors = {};
	if (!name) {
		errors.name = "Name is required!";
	}
	if (!price) {
		errors.price = "Price is required!";
	}
	if (!currency) {
		errors.currency = "Currency is required!";
	}
	return errors;
};
