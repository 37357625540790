import { SaveButton, Toolbar } from "react-admin";
import { CustomDeleteButtonWithConfirmation } from "./CustomButtons";

export const CustomToolbar = ({ deleteButton, source, ...props }) => (
	<Toolbar {...props}>
		<SaveButton />
		{deleteButton ? (
			<CustomDeleteButtonWithConfirmation source={source} />
		) : null}

		{props.children}
	</Toolbar>
);
