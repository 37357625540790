import {
	Show,
	SimpleShowLayout,
	TextField,
	BooleanField,
	ArrayField,
	SingleFieldList,
	ChipField,
	useRedirect
} from "react-admin";


const CustomChipFieldWithRedirect = ({
	record: { feature: { id } },
	source,
	basePath,
	emptyText,
	redirectToResource,
	...props
}) => {
	const redirect = useRedirect();
	const handleRedirectClick = () => {
		redirect("show", `/${redirectToResource}`, id);
	};

	return (
		<ChipField
			onClick={handleRedirectClick}
			source={source}
			emptyText={emptyText}
			{...props}
		/>
	);
};

export const PlanShow = (props) => {
	return (
		<Show {...props} title={"Show Plan"}>
			<SimpleShowLayout>
				<TextField source="index" label={"Display Index"} />
				<BooleanField source="isActive" label={"Is Active"} />
				<BooleanField source="isMultiplePurchaseRequired" label={"Requires Multiple Purchases"} />
				<TextField source="title" />
				<TextField source="description" />
				<TextField source="price" />
				<TextField source="priceDescription" label={"Price Description"} />
				<TextField source="minimumPurchases" />
				<ArrayField source="PlanFeature">
					<SingleFieldList linkType={false}>
						<CustomChipFieldWithRedirect
							source="feature.title"
							redirectToResource="features"
						/>
					</SingleFieldList>
				</ArrayField>
			</SimpleShowLayout>
		</Show>
	);
};
