import {
	Create,
	TextInput,
	SimpleForm,
	useNotify,
	useRedirect,
	useRefresh,
	BooleanInput,
} from "react-admin";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { validateVaccineData } from "./vaccineValidate";


export const VaccineCreate = (props) => {
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();

	const onSuccess = () => {
		notify("Success!", "info");
		redirect("/vaccines");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<Create
			{...props}
			title={"Add Vaccine"}
			onSuccess={onSuccess}
			onFailure={onFailure}
		>
			<SimpleForm
				toolbar={<CustomToolbar />}
				validate={validateVaccineData}
				syncWithLocation={false}
			>
				<TextInput source="name" />
				<BooleanInput source="isCertificateRequired" label={"Requires Certificate"} />
				<BooleanInput source="isQrCodeRequired" label={"Requires Qr Code"} />
			</SimpleForm>
		</Create>
	);
};
