import {
	Show,
	SimpleShowLayout,
	TextField,
	BooleanField
} from "react-admin";


export const VaccineShow = (props) => {
	return (
		<Show {...props} title={"Show Vaccine"}>
			<SimpleShowLayout>
				<TextField source="index" label={"Display Index"} />
				<BooleanField source="isCertificateRequired" label={"Requires Certificate"} />
				<BooleanField source="isQrCodeRequired" label={"Requires Qr Code"} />
			</SimpleShowLayout>
		</Show>
	);
};
