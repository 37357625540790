import { DeleteButton, useLocale, useTranslate } from "react-admin";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import classnames from "classnames";
import { useState } from "react";

import { CustomDialog } from "./CustomDialog";

export const CustomDeleteButtonWithConfirmation = ({
	record,
	source,
	resource,
	confirmTitle,
	confirmContent = "ra.message.delete_content",
	...props
}) => {
	const translate = useTranslate();
	const locale = useLocale();
	return (
		<DeleteButton
			{...props}
			resource={resource}
			redirect={resource}
			confirmTitle={
				confirmTitle ||
				translate(`${resource}.delete.confirmTitle`, {
					[source]: `${
						record?.[source]?.[locale] || record?.[source]
					}`,
				})
			}
			confirmContent={confirmContent}
			record={record}
			mutationMode={"pessimistic"}
		/>
	);
};

export const CustomButton = ({
	variant,
	onClick,
	type,
	innerStyle,
	innerText,
	icon,
	...rest
}) => {
	const translate = useTranslate();

	if (type === "refresh")
		return (
			<Button variant={variant} onClick={onClick}>
				<Typography>{translate("actions.refresh")}</Typography>
			</Button>
		);

	return (
		<Button {...rest} variant={variant} onClick={onClick}>
			<Box {...innerStyle}>
				<Box pr="0.5rem">{icon}</Box>
				<span>{translate(innerText)}</span>
			</Box>
		</Button>
	);
};

export const CustomDeleteButtonWithCustomConfirm = ({
	record,
	title = null,
	content = null,
	...rest
}) => {
	const [open, setOpen] = useState(false);

	const translate = useTranslate();
	const classes = useStyles({ record, ...rest });

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	if (record.isDeletable && !record.isDefault)
		return (
			<CustomDeleteButtonWithConfirmation
				label="ra.action.delete"
				{...rest}
				record={record}
			/>
		);

	return (
		<>
			<Button
				onClick={handleClickOpen}
				variant="text"
				className={classnames("ra-delete-button", classes.deleteButton)}
				startIcon={<DeleteIcon color="error" />}
			>
				{translate("buttons.delete")}
			</Button>
			<CustomDialog
				open={open}
				onClose={handleClose}
				title={
					title ? title : "messages.confirmDialog.notDeletable.title"
				}
				content={
					content
						? content
						: "messages.confirmDialog.notDeletable.content"
				}
			>
				<CustomButton
					onClick={handleClose}
					innerStyle={{
						display: "flex",
						alignItems: "unset",
						justifyContent: "center",
					}}
					innerText="buttons.confirm"
					icon={<CheckCircleIcon />}
					className={classnames("ra-confirm")}
					color="primary"
					autoFocus
				/>
			</CustomDialog>
		</>
	);
};

const useStyles = makeStyles(
	(theme) => ({
		deleteButton: {
			color: theme.palette.error.main,
			"&:hover": {
				backgroundColor: fade(theme.palette.error.main, 0.12),
				// Reset on mouse devices
				"@media (hover: none)": {
					backgroundColor: "transparent",
				},
			},
		},
	}),
	{ name: "RaDeleteWithConfirmButton" }
);

const customButtons = {
	CustomDeleteButtonWithConfirmation,
};

export default customButtons;
