export const constants = {
	perPage: 15,
	perPageMax: 1000000000,
	periodOptions: [{ id: "monthly", title: "Monthly" }, { id: "yearly", title: "Yearly" }],
	planTypes: [{ id: "basic", title: "Basic Plan" }, { id: "family", title: "Family Plan" }],
	curruncies: [{ id: "$", title: "$" }]

};

export default constants;
