import Layout from "./layout/Layout";
import Login from "./layout/Login";
import { Admin, Resource } from "react-admin";
import * as React from "react";
import themeReducer from "./themeReducer";
import diseases from "./resources/diseases";
import features from "./resources/features";
import allergyTypes from "./resources/allergyTypes";
import vaccines from "./resources/vaccines";
import plans from "./resources/plans";
import products from "./resources/products";

import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";

import customRoutes from "./pages/customRoutes";
import { envSettings } from "./settings/env";

import authProvider from "./providers/authProvider";
import DataProvider from "./providers/dataProvider";

const dataProvider = DataProvider(envSettings.baseUrl);

const App = () => (
	<Admin
		customRoutes={customRoutes}
		catchAll={NotFound}
		customReducers={{ theme: themeReducer }}
		dashboard={Dashboard}
		layout={Layout}
		authProvider={authProvider}
		dataProvider={dataProvider}
		loginPage={Login}
	>
		<Resource name="diseases" {...diseases} />
		<Resource name="allergies" {...allergyTypes} />
		<Resource name="vaccines" {...vaccines} />
		<Resource name="plans" {...plans} />
		<Resource name="products" {...products} />
		<Resource name="features" {...features} />
	</Admin>
);

export default App;
