import React, { useEffect, useState, Fragment } from "react";
import {
	SimpleForm,
	useNotify,
	useRedirect,
	Toolbar,
	TextInput,
} from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { envSettings } from "../settings/env";
import axios from "axios";
import { buildErrorMessage } from "../utils/helpers";
import { AutocompleteArrayInput } from 'react-admin';
const { countries } = require('country-data');

export const AppSettings = () => {
	const [loading, setLoading] = useState(false);
	const [iosMinimumVersion, setIosMinimumVersion] = useState(null);
	const [iosRecommendedVersion, setIosRecommendedVersion] = useState(null);
	const [androidMinimumVersion, setAndroidMinimumVersion] = useState(null);
	const [androidRecommendedVersion, setAndroidRecommendedVersion] =
		useState(null);
	const [blacklistedCountries, setBlacklistedCountries] =
		useState(null);
	const notify = useNotify();
	const redirect = useRedirect();
	const countryCodesWithNames = countries.all.map((country) => ({
		id: `${country.countryCallingCodes[0]}`,
		name: country.name,
	}));

	useEffect(() => {
		(async () => {
			const res = await axios.get(envSettings.baseUrl + "/settings/cms/app", {
				withCredentials: true,
			});
			setBlacklistedCountries(res?.data?.settings?.blacklistedCountries);
			setIosMinimumVersion(res?.data?.settings?.iosMinimumVersion);
			setIosRecommendedVersion(res?.data?.settings?.iosRecommendedVersion);
			setAndroidMinimumVersion(res?.data?.settings?.androidMinimumVersion);
			setAndroidRecommendedVersion(
				res?.data?.settings?.androidRecommendedVersion
			);
			setLoading(false);
		})();
	}, []);

	const validateAppSettings = async ({
		iosMinimumVersion,
		iosRecommendedVersion,
		androidMinimumVersion,
		androidRecommendedVersion,
	}) => ({
		...(!iosRecommendedVersion && {
			iosRecommendedVersion: "Required",
		}),
		...(!androidRecommendedVersion && {
			androidRecommendedVersion: "Required",
		}),
		...(!iosMinimumVersion && {
			iosMinimumVersion: "Required",
		}),
		...(!androidMinimumVersion && {
			androidMinimumVersion: "Required",
		})
	});

	const onFailure = (error) => {
		notify(buildErrorMessage(error), "warning");
	};
	return (
		<Card>
			<CardContent>
				{loading ? (
					<center>
						<h1> we are loading data</h1>
					</center>
				) : (
					<Fragment>
						<SimpleForm
							toolbar={<Toolbar alwaysEnableSaveButton />}
							submitOnEnter={false}
							save={async (data) => {
								try {
									const res = await axios.post(
										envSettings.baseUrl + "/settings/cms/app",
										data,
										{ withCredentials: true }
									);
									if (res.status === 200) {
										notify(
											"Updated Successfully!",
											"success"
										);
										redirect("/app-settings");
									} else {
										notify("Failed!", "error");
									}
								} catch (error) {
									onFailure(error);
								}
							}}
							validate={validateAppSettings}
						>
							<TextInput
								source="iosMinimumVersion"
								label={"IOS Minimum Version"}
								optionText={"title"}
								defaultValue={iosMinimumVersion}
								style={{ float: "left" }}
							/>
							<TextInput
								source="iosRecommendedVersion"
								label={"IOS Recommended Version"}
								optionText={"title"}
								defaultValue={iosRecommendedVersion}
								style={{ marginLeft: "20px" }}
							/>
							<TextInput
								source="androidMinimumVersion"
								label={"Android Minimum Version"}
								optionText={"title"}
								defaultValue={androidMinimumVersion}
								style={{ float: "left" }}
							/>
							<TextInput
								source="androidRecommendedVersion"
								label={"Android Recommended Version"}
								optionText={"title"}
								defaultValue={androidRecommendedVersion}
								style={{ marginLeft: "20px" }}
							/>
							<AutocompleteArrayInput
								source="blacklistedCountries"
								choices={countryCodesWithNames}
								defaultValue={blacklistedCountries}
							/>
						</SimpleForm>
					</Fragment>
				)}
			</CardContent>
		</Card >
	);
};
