import * as React from "react";
import { Title } from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

export const NotFound = () => (
	<Card>
		<Title title="Not Found" />
		<CardContent>
			<center>
				<h1>404: Page not found</h1>
			</center>
		</CardContent>
	</Card>
);

export default NotFound;
