import * as React from "react";
import { useSelector } from "react-redux";
import { Layout } from "react-admin";
import { CustomAppBar } from "./CustomAppBar";
import Menu from "./Menu";
import { darkTheme, lightTheme } from "./themes";
export default (props) => {
	const theme = useSelector((state) =>
		state.theme === "dark" ? darkTheme : lightTheme
	);
	return (
		<Layout
			{...props}
			appBar={CustomAppBar}
			menu={Menu}
			theme={theme}
		/>
	);
};
