export const validatePlanData = async ({
	title, type, price, priceDescription, minimumPurchases, description
}) => {
	const errors = {};
	if (!title) {
		errors.title = "Title is required!";
	}
	if (!type) {
		errors.type = "Type is required!";
	}
	if (!price) {
		errors.price = "Price is required!";
	}
	if (!priceDescription) {
		errors.priceDescription = "price description is required!";
	}
	if (!minimumPurchases) {
		errors.minimumPurchases = "MinimumPurchases is required!";
	}
	if (!description) {
		errors.description = "Description is required!";
	}
	return errors;
};
