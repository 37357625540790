import { forwardRef, useEffect, useMemo, useState } from "react";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import Brightness3Icon from "@material-ui/icons/Brightness3";
import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import Logo from "./Logo";

import {
	AppBar,
	Layout,
	useGetIdentity,
	useLocale,
	useRedirect,
	useTranslate,
	usePermissions,
	useNotify,
} from "react-admin";
import {
	Badge,
	Box,
	Button,
	Fade,
	Grow,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemText,
	Menu,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { changeTheme } from "../actions";
import { useStyles } from "../utils/styles";


export const CustomAppBar = ({ ...props }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useSelector((state) => state.theme);

	return (
		<AppBar {...props}>
			<Typography
				variant="h6"
				color="inherit"
				className={classes.title}
				id="react-admin-title"
			/>

			<div className={classes.spacer}>
				<Logo />
			</div>
			{theme === "dark" ? (
				<Tooltip title="Light" enterDelay={50} leaveDelay={50}>
					<IconButton
						value="light"
						onClick={() => dispatch(changeTheme("light"))}
					>
						<WbSunnyIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Dark" enterDelay={50} leaveDelay={50}>
					<IconButton
						value="dark"
						onClick={() => dispatch(changeTheme("dark"))}
					>
						<Brightness3Icon />
					</IconButton>
				</Tooltip>
			)}
		</AppBar>
	);
};
