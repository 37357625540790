import {
	Show,
	SimpleShowLayout,
	TextField,
} from "react-admin";
import CustomImageField from "../../customComponents/CustomImageField";


export const ProductShow = (props) => {
	return (
		<Show {...props} title={"Show Product"}>
			<SimpleShowLayout>
				<CustomImageField
					source="imageUrl"
					label={"Product Image"}
					sortable={false}
				/>
				<TextField source="index" label={"Display Index"} />
				<TextField source="name" />
				<TextField source="currency" />
				<TextField source="price" />
			</SimpleShowLayout>
		</Show>
	);
};
