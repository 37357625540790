import {
	Datagrid,
	EditButton,
	Filter,
	List,
	ShowButton,
	TextField,
	TextInput,
} from "react-admin";

import { CustomDeleteButtonWithConfirmation } from "../../customComponents/CustomButtons";
import { constants } from "../../utils/constants";

const FeatureFilter = (props) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
	</Filter>
);

export const FeatureList = (props) => {
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<FeatureFilter />}
		>
			<Datagrid>
				<TextField source="index" label={"Display Index"} />
				<TextField source="title" />
				<ShowButton />
				<EditButton />
				<CustomDeleteButtonWithConfirmation
					confirmTitle={"Delete Feature"}
					confirmContent={"ra.message.delete_content"}
					{...props}
				/>
			</Datagrid>
		</List>
	);
};
