/** @format */
const environment = "dev";
const env = {
	prod: {
		baseUrl: "https://stageapi.helplive.com/api/v1",
		socketBaseUrl: "https://stageapi.helplive.com/",
	},
	dev: {
		baseUrl: "https://api.helplive.online/api/v1",
		socketBaseUrl: "https://api.helplive.online/",
	},
};
export const envSettings = env[environment];