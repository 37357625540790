import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import VaccinesIcon from '@mui/icons-material/Vaccines';
import UserIcon from "@material-ui/icons/People";
import PaidIcon from '@mui/icons-material/Paid';
import { makeStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import CategoryIcon from '@mui/icons-material/Category';
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import PersonIcon from '@mui/icons-material/Person';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import classnames from "classnames";
import {
	DashboardMenuItem,
	MenuItemLink,
} from "react-admin";
import SubMenu from "./SubMenu";

const Menu = ({ dense = false }) => {
	const [state, setState] = useState({
		menuSettings: true,
		menuPlans: true,
	});

	const open = useSelector((state) => state.admin.ui.sidebarOpen);
	useSelector((state) => state.theme);
	const classes = useStyles();

	const handleToggle = (menu) => {
		setState((state) => ({ ...state, [menu]: !state[menu] }));
	};
	const getResources = () => {
		const resources = [<DashboardMenuItem key="menu-22" />];
		resources.push(
			<>
				<MenuItemLink
					to={{
						pathname: "/allergies",
						state: { _scrollToTop: true },
					}}
					primaryText={"Allergy Types"}
					leftIcon={<UserIcon />}
					dense={dense}
					key="menu-21"
				/><MenuItemLink
					to={{
						pathname: "/vaccines",
						state: { _scrollToTop: true },
					}}
					primaryText={"Vaccines"}
					leftIcon={<VaccinesIcon />}
					dense={dense}
					key="menu-21"
				/>
				<MenuItemLink
					to={{
						pathname: "/diseases",
						state: { _scrollToTop: true },
					}}
					primaryText={"Diseases"}
					leftIcon={<CoronavirusIcon />}
					dense={dense}
					key="menu-21"
				/>
				<Fragment>
					<SubMenu
						key="menu-14"
						handleToggle={() => handleToggle("menuPlans")}
						isOpen={state.menuPlans}
						name="Plans"
						icon={<PaidIcon />}
						dense={dense}
					>
						<MenuItemLink
							to={{
								pathname: "/plans",
								state: { _scrollToTop: true },
							}}
							primaryText={"Plans"}
							leftIcon={<PaidIcon />}
							dense={dense}
							key="menu-21"
						/>
						<MenuItemLink
							to={{
								pathname: "/features",
								state: { _scrollToTop: true },
							}}
							primaryText={"Features"}
							leftIcon={<FormatListBulletedIcon />}
							dense={dense}
							key="menu-21"
						/>
					</SubMenu>

				</Fragment>

				<MenuItemLink
					to={{
						pathname: "/products",
						state: { _scrollToTop: true },
					}}
					primaryText={"Products"}
					leftIcon={<CategoryIcon />}
					dense={dense}
					key="menu-21"
				/>
				<Fragment>
					<SubMenu
						key="menu-14"
						handleToggle={() => handleToggle("menuSettings")}
						isOpen={state.menuSettings}
						name="Settings"
						icon={<SettingsIcon />}
						dense={dense}
					>
						<MenuItemLink
							to={{
								pathname: "/app-settings",
								state: { _scrollToTop: true },
							}}
							primaryText="App Settings"
							leftIcon={<SettingsApplicationsIcon />}
							dense={dense}
						/>

					</SubMenu>

				</Fragment>
			</>
		);
		return resources;
	};

	return (
		<div
			className={classnames(classes.root, {
				[classes.open]: open,
				[classes.closed]: !open,
			})}
		>
			{getResources()}
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	open: {
		width: "100%",
	},
	closed: {
		width: 55,
	},
}));

export default Menu;
