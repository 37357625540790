import { useEffect, useState } from "react";
import {
	Create,
	TextInput,
	SimpleForm,
	useNotify,
	useRedirect,
	useRefresh,
	BooleanInput,
	NumberInput,
	SelectInput,
	useGetList,
	AutocompleteArrayInput
} from "react-admin";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { validatePlanData } from "./planValidate";
import { constants } from "../../utils/constants"

export const PlanCreate = (props) => {
	const [featuresArray, setFeaturesArray] = useState([]);
	const { data: featuresData } = useGetList("features");

	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();

	useEffect(() => {
		setFeaturesArray(
			Object.values(featuresData).map(({ id, title }) =>
				({ id, name: title })
			)
		);
	}, [featuresData]);

	const onSuccess = () => {
		notify("Success!", "info");
		redirect("/plans");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<Create
			{...props}
			title={"Add Plan"}
			onSuccess={onSuccess}
			onFailure={onFailure}
		>
			<SimpleForm
				toolbar={<CustomToolbar />}
				validate={validatePlanData}
				syncWithLocation={false}
			>
				<TextInput source="title" />
				<SelectInput
					source="type"
					choices={constants.planTypes}
					optionText="title"
				/>
				<TextInput source="description" />
				<NumberInput source="price" />
				<TextInput source="priceDescription" label={"Price Description"} />
				<NumberInput source="minimumPurchases" min={1} />
				<BooleanInput source="isMultiplePurchaseRequired" label={"Requires Multiple Purchases"} />
				<NumberInput source="discountPercentage" label={"Discount Percentage (optional)"} />
				<AutocompleteArrayInput
					resettable
					source="featureIds"
					choices={featuresArray}
					
				/>
				<BooleanInput source="isActive" label={"Is Active"} />
			</SimpleForm>
		</Create>
	);
};
