import { AllergyTypeCreate } from "./allergyTypeCreate";
import { AllergyTypeEdit } from "./allergyTypeEdit";
import { AllergyTypeList } from "./allergyTypeList";
import { AllergyTypeShow } from "./allergyTypeShow";

export default {
	create: AllergyTypeCreate,
	edit: AllergyTypeEdit,
	list: AllergyTypeList,
	show: AllergyTypeShow,
};
