import { envSettings } from "../settings/env";
import { fetchUtils } from "ra-core";

const { publicUrls } = require("../utils/constants");

const httpClient = fetchUtils.fetchJson;

export const authServices = {
	login: ({ username, password }) => {
		const request = new Request(envSettings.baseUrl + "/auth/cms/login", {
			method: "POST",
			body: JSON.stringify({ username, password }),
			headers: new Headers({ "Content-Type": "application/json" }),
			credentials: "include",
		});
		return fetch(request)
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.message);
				}
				return response.json();
			})
			.then((response) => {
				localStorage.setItem("authenticated", "true");
				localStorage.setItem("accessToken", response?.accessToken);
			});
	},

	checkError: (error) => {
		return Promise.resolve();
	},
	checkAuth: () => {
		if (localStorage.getItem("authenticated") !== "true")
			return Promise.reject();
		const accessToken = localStorage.getItem('accessToken');
		const request = new Request(envSettings.baseUrl + "/account/cms/me", {
			method: "GET",
			headers: new Headers({ "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}` }),
			credentials: "include",
		});
		return fetch(request).then((response) => {
			if (response.status !== 200) {
				return Promise.reject();
			} else return Promise.resolve();
		});
	},

	logout: () => {
		if (localStorage.getItem("authenticated") !== "true")
			return Promise.resolve();
		const accessToken = localStorage.getItem('accessToken');
		const request = new Request(envSettings.baseUrl + "/users/logout", {
			method: "POST",
			headers: new Headers({ "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}` }),
			credentials: "include",
		});
		return fetch(request)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject();
				} else {
					localStorage.removeItem("authenticated");
					return Promise.resolve();
				}
			})
			.catch((err) => {
				console.log(err);
			});
	},

	getIdentity: () => {
		const accessToken = localStorage.getItem('accessToken');
		return httpClient(envSettings.baseUrl + "/account/cms/me", {
			method: "GET",
			headers: new Headers({ "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}` }),
			credentials: "include",
		}).then(({ body }) => {
			const { id, username } = JSON.parse(body);

			return Promise.resolve({
				id,
				username,
			});
		});
	},

	getPermissions: () => {
		if (localStorage.getItem("authenticated") !== "true") {
			return Promise.reject();
		}
		return Promise.resolve();
		// const request = new Request(envSettings.baseUrl + "/users/permissions", {
		// 	method: "GET",
		// 	headers: new Headers({ "Content-Type": "application/json" }),
		// 	credentials: "include",
		// });
		// return fetch(request)
		// 	.then((response) => {
		// 		if (response.status !== 200) {
		// 			return Promise.reject();
		// 		} else {
		// 			return response.json();
		// 		}
		// 	})
		// 	.then(() => {
		// 		return Promise.resolve();
		// 	});
	},
};

export default authServices;
