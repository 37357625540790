import {
	Datagrid,
	EditButton,
	Filter,
	List,
	ShowButton,
	TextField,
	TextInput,
} from "react-admin";

import { CustomDeleteButtonWithConfirmation } from "../../customComponents/CustomButtons";
import { constants } from "../../utils/constants";

const AllergyTypeFilter = (props) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
	</Filter>
);

export const AllergyTypeList = (props) => {
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<AllergyTypeFilter />}
		>
			<Datagrid>
				<TextField source="index" label={"Display Index"} />
				<TextField source="name" />
				<ShowButton />
				<EditButton />
				<CustomDeleteButtonWithConfirmation
					confirmTitle={"Delete Allergy Type"}
					confirmContent={"ra.message.delete_content"}
					{...props}
				/>
			</Datagrid>
		</List>
	);
};
