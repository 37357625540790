import { useRecordContext } from "react-admin";
import PropTypes from "prop-types";
import * as React from "react";

const CustomImageField = (props) => {
	const { source, title, className, customLabel } = props;
	const record = useRecordContext(props);

	return (
		<div className={className?.imgContainer} {...props}>
			<img
				title={title || record.title}
				alt={title || record.title}
				src={record[source]}
				className={className?.img}
				width="150px"
				height="150px"
				{...props}
			/>
			{customLabel && <h6>{customLabel}</h6>}
		</div>
	);
};

CustomImageField.propTypes = {
	title: PropTypes.string,
	record: PropTypes.object,
	source: PropTypes.string.isRequired,
};

export default CustomImageField;
