import { DiseaseCreate } from "./diseaseCreate";
import { DiseaseEdit } from "./diseaseEdit";
import { DiseaseList } from "./diseaseList";
import { DiseaseShow } from "./diseaseShow";

export default {
	create: DiseaseCreate,
	edit: DiseaseEdit,
	list: DiseaseList,
	show: DiseaseShow,
};
