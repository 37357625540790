
import { useEffect, useState } from "react";
import {
	Edit,
	SimpleForm,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh,
	NumberInput,
	NumberField,
	BooleanInput,
	SelectInput,
	AutocompleteArrayInput,
	useGetList
} from "react-admin";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { validatePlanData } from "./planValidate";
import { constants } from "../../utils/constants"

const CustomAutoComplete = ({ record: { featureIds = [] }, featuresArray }) => {
	return <AutocompleteArrayInput
		resettable
		source="featureIds"
		choices={featuresArray}
		value={featureIds}
	/>;
}


export const PlanEdit = (props) => {
	const [featuresArray, setFeaturesArray] = useState([]);
	const { data: featuresData } = useGetList("features");
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();


	useEffect(() => {
		setFeaturesArray(
			Object.values(featuresData).map(({ id, title }) =>
				({ id, name: title })
			)
		);
	}, [featuresData]);

	const onSuccess = () => {
		notify(`Updated Successfully!`);
		redirect("/plans");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<Edit
			{...props}
			onSuccess={onSuccess}
			onFailure={onFailure}
			mutationMode="pessimistic"
			title={"Edit Plan"}
		>
			<SimpleForm
				toolbar={<CustomToolbar />}
				validate={validatePlanData}
				syncWithLocation={false}
			>
				<TextInput source="title" />
				<SelectInput
					source="type"
					choices={constants.planTypes}
					optionText="title"
				/>
				<TextInput source="description" />
				<NumberInput source="price" />
				<TextInput source="priceDescription" label={"Price Description"} />
				<NumberInput source="minimumPurchases" min={1} />
				<BooleanInput source="isMultiplePurchaseRequired" label={"Requires Multiple Purchases"} />
				<NumberInput source="discountPercentage" label={"Discount Percentage (optional)"} />
				<CustomAutoComplete {...props} featuresArray={featuresArray} />
				<BooleanInput source="isActive" label={"Is Active"} />
				<NumberField source="index" label="Display Index" />
				<NumberInput source="newIndex" />
			</SimpleForm>
		</Edit>
	);
};
