import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { usePermissions } from "react-admin";

const Dashboard = () => {
	const { loading, permissions } = usePermissions();
	let temp = null;
	if (permissions) {
		temp = <span>administrator</span>;
	}
	return (
		<Card>
			<CardContent>
				{loading ? (
					<center>
						<h1> we are loading your data</h1>
					</center>
				) : (
					<center>
						<h1> Welcome {temp}!</h1>
					</center>
				)}
			</CardContent>
		</Card>
	);
};
export default Dashboard;
