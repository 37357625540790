import * as React from "react";
import { useTheme } from "@material-ui/core/styles";

const Logo = (props) => {
	const theme = useTheme();
	return (
		// <svg width={234.532} height={20.475} viewBox="0 0 62.053 5.417" {...props}>
		<svg
			width="234.532"
			height="20.475"
			viewBox="0 0 725.977 75.586"
			xmlns="http://www.w3.org/2000/svg"
		>
			<text x="50%" y="70%"
				aria-label="~Help Live~"
				style={{
					lineHeight: 1.25,
				}}
				fontWeight="bold"
				fontSize={80}
				fontFamily="Permanent Marker"
				letterSpacing={0}
				wordSpacing={0}
				strokeWidth={0.265}
				fill={theme.palette.secondary.light}
				dominant-baseline="middle"
				text-anchor="middle"
				font-family="Permanent Marker"
				font-style="italic"
			>~ HELP LIVE ~</text>

		</svg>
	);
};

export default Logo;
