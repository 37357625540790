import {
	Create,
	TextInput,
	SimpleForm,
	useNotify,
	useRedirect,
	useRefresh,
	ImageInput,
	NumberInput,
	SelectInput,
	ImageField,
	useCreate
} from "react-admin";
import { transformToFormData } from "../../utils/customFunctions";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { validateProductData } from "./productValidate";
import { constants } from "../../utils/constants"

export const ProductCreate = (props) => {
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();
	const [create] = useCreate("products");

	const onSuccess = () => {
		notify("Success!", "info");
		redirect("/products");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<Create
			{...props}
			title={"Add Product"}
			onSuccess={onSuccess}
			onFailure={onFailure}
		>
			<SimpleForm
				toolbar={<CustomToolbar />}
				save={(data) => {
					const transformed = transformToFormData(data);
					create(
						{ payload: { data: transformed } },
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				validate={validateProductData}
				syncWithLocation={false}
			>
				<TextInput source="name" />
				<SelectInput
					source="currency"
					choices={constants.curruncies}
					optionText="title"
				/>
				<NumberInput source="price" />
				<ImageInput
					source="image"
					label="Product Image"
					accept="image/*"
					placeholder={"Upload Product Image Here"}
				>
					<ImageField source="src" />
				</ImageInput>
			</SimpleForm>
		</Create>
	);
};
