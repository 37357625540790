import {
	Show,
	SimpleShowLayout,
	TextField,
} from "react-admin";


export const AllergyTypeShow = (props) => {
	return (
		<Show {...props} title={"Show Allergy Type"}>
			<SimpleShowLayout>
				<TextField source="index" label={"Display Index"} />
				<TextField source="name" />
			</SimpleShowLayout>
		</Show>
	);
};
