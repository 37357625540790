import { VaccineCreate } from "./vaccineCreate";
import { VaccineEdit } from "./vaccineEdit";
import { VaccineList } from "./vaccineList";
import { VaccineShow } from "./vaccineShow";

export default {
	create: VaccineCreate,
	edit: VaccineEdit,
	list: VaccineList,
	show: VaccineShow,
};
