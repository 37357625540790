import {
	Create,
	TextInput,
	SimpleForm,
	useNotify,
	useRedirect,
	useRefresh,
} from "react-admin";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { validateFeatureData } from "./featureValidate";


export const FeatureCreate = (props) => {
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();

	const onSuccess = () => {
		notify("Success!", "info");
		redirect("/features");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<Create
			{...props}
			title={"Add Feature"}
			onSuccess={onSuccess}
			onFailure={onFailure}
		>
			<SimpleForm
				toolbar={<CustomToolbar />}
				validate={validateFeatureData}
				syncWithLocation={false}
			>
				<TextInput source="title" />
			</SimpleForm>
		</Create>
	);
};
