import { PlanCreate } from "./planCreate";
import { PlanEdit } from "./planEdit";
import { PlanList } from "./planList";
import { PlanShow } from "./planShow";

export default {
	create: PlanCreate,
	edit: PlanEdit,
	list: PlanList,
	show: PlanShow,
};
