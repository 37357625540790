
import {
	Edit,
	SimpleForm,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh,
	NumberInput,
	NumberField,
	ImageInput,
	ImageField,
	SelectInput,
	useUpdate
} from "react-admin";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { validateProductData } from "./productValidate";
import { constants } from "../../utils/constants"
import { transformToFormData } from "../../utils/customFunctions";

export const ProductEdit = (props) => {
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();
	const [update] = useUpdate("products", props.id);

	const onSuccess = () => {
		notify(`Updated Successfully!`);
		redirect("/products");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<Edit
			{...props}
			onSuccess={onSuccess}
			onFailure={onFailure}
			mutationMode="pessimistic"
			title={"Edit Product"}
		>
			<SimpleForm
				toolbar={<CustomToolbar />}
				validate={validateProductData}
				save={(data) => {
					const transformed = transformToFormData(data);
					update(
						{ payload: { data: transformed } },
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				syncWithLocation={false}
			>
				<TextInput source="name" />
				<SelectInput
					source="currency"
					choices={constants.curruncies}
					optionText="title"
				/>
				<NumberInput source="price" />
				<ImageInput
					source="image"
					label="Product Image"
					accept="image/*"
					placeholder={"Upload Product Image Here"}
				>
					<ImageField source="src" />
				</ImageInput>
				<NumberField source="index" label="Display Index" />
				<NumberInput source="newIndex" />
			</SimpleForm>
		</Edit>
	);
};
