import { ProductCreate } from "./productCreate";
import { ProductEdit } from "./productEdit";
import { ProductList } from "./productList";
import { ProductShow } from "./productShow";

export default {
	create: ProductCreate,
	edit: ProductEdit,
	list: ProductList,
	show: ProductShow,
};
