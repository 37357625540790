import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
	imgSmall: {
		height: "80px",
		width: "80px",
		margin: "0.5rem 0rem",
		maxHeight: "10rem",
	},
	inline: {
		float: "left",
		marginRight: "50px",
	},
	no: {
		float: "initial",
	},
	headerCell: {
		fontFamily: "bold",
		fontSize: "16px",
	},
	title: {
		flex: 1,
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
	},
	spacer: {
		flex: 1,
	},
});
